<template>
    <h5 class="mb-3">
        <v-icon :icon="icon"/>
        {{ text }}
    </h5>
</template>

<script>
export default {
    props: {
        text: String,
        icon: String,
    },
    data() {
        return {};
    },
}
</script>
