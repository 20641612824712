<template>
    <div class="view-users">
        <generic-view-title :title="teamScoped ? 'Team members' : 'All Users'">
            <div class="d-inline-block">
                <v-btn :disabled="createDisabled"
                       icon="spx:IconPlus"
                       @click="create"
                       density="comfortable"
                       rounded="xl"/>
                <v-tooltip v-if="createDisabled"
                           activator="parent"
                           location="bottom"
                           :text="createDisabledReason"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="users"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/${routeBasePath}/users/${item.id}`">{{ item.firstname }} {{ item.name }}</router-link>
            </template>

            <template v-slot:item.roles="{item}">
                <span v-if="item.roles.length">{{ item.roles[0].name }}</span>
            </template>

            <template v-slot:item.team_id="{item}" v-if="teams.length">
                <span v-if="item.team_id">{{ teams.filter(t => t.id === item.team_id).pop().name }}</span>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="spx:edit" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="spx:trash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import formatTimeMixin from "../../Shared/Mixins/formatTimeMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, formatTimeMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        this.setViewState();

        this.setHeaders(this.teamScoped);

        return {
            createDisabled: false,
            createDisabledReason: '',
        };
    },
    computed: mapState({
        users (state, getters) {
            return getters[this.storeNamespace + '/users']
        },
        total (state, getters) {
            return getters[this.storeNamespace + '/total']
        },
        teams (state, getters) {
            return !this.teamScoped ? getters['team/teams'] : [];
        }
    }),
    methods: {
        seedTable(options) {
            this.handlePagination(`${this.storeNamespace}/index`, {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        create() {
            this.$router.push(`/${this.routeBasePath}/users/create`);
        },
        edit(user) {
            this.$router.push(`/${this.routeBasePath}/users/${user.id}`);
        },
        destroy(user) {
            this.$store.dispatch(`${this.storeNamespace}/destroy`, +user.id)
                .then(() => this.deletedMessage('User'))
                .catch(e => this.handleHttpError(e));
        },
        async allowUserCreation() {
            if (!this.$store.getters['team/canCreateMembers']) {
                this.createDisabled = true;
                this.createDisabledReason = 'Upgrade your plan to create more users';
            }
        },
        /**
         * This view is used for both team member management and super admin user management.
         * The `teamScoped` variable distinguishes which vue-router and vuex paths to use.
         */
        setViewState() {
            this.teamScoped = /^\/team\//i.test(this.$route.path);

            this.routeBasePath = this.teamScoped ? 'team' : 'admin';

            this.storeNamespace = this.teamScoped ? 'teamMembers' : 'users';

            if (this.teamScoped) {
                this.allowUserCreation();
            } else {
                this.$store.dispatch('team/index');
            }
        },
        setHeaders(teamScoped) {
            this.headers = [
                {key: 'name', title: 'name', sortable: true},
                {key: 'roles', title: 'role'},
                {key: 'created_at', title: 'created on', value: item => `${this.formatDate(item.created_at)}`},
                {key: 'actions', title: '', sortable: false, align: 'end'},
            ];

            if (!teamScoped) {
                this.headers.splice(1, 0, {
                    key: 'team_id',
                    title: 'team',
                    sortable: true,
                });
            }
        }
    }
}
</script>
