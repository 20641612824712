<template>
    <v-chip :color="color" :text="text" variant="tonal"/>

</template>

<script>
export default {
    props: {
        dcp: Object,
    },
    data() {
        return {
            text: this.setText(this.dcp.status, this.dcp.workflow_log.progress),
            color: this.setColor(this.dcp.status)
        };
    },
    methods: {
        setText(status, progress) {
            if (status === 'done') return status;
            return progress + '%';
        },
        setColor(text) {
            switch (text) {
                case 'done':
                    return 'success';
                default:
                    return 'error';
            }
        }
    }
}
</script>
