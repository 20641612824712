export default {
    // Shared domain
    PAGINATION_PER_PAGE: 'pagination_per_page',
    NOTIFICATIONS: 'notifications',
    NOTIFICATIONS_TOTAL: 'notifications_total',
    NOTIFICATIONS_PAGE: 'notifications_page',
    NOTIFICATIONS_UNREAD_COUNT: 'notification_unread_count',

    // Auth domain
    USER: 'user',
    USERS: 'users',
    USERS_TOTAL: 'users_total',
    USERS_PAGE: 'users_page',
    LOGGED: 'logged',

    // Prepare domain
    DCPS: 'dcps',
    DCPS_TOTAL: 'dcps_total',
    DCPS_PAGE: 'dcps_page',

    // Plan domain
    OUTPUT_PROFILES: 'output_profiles',
    OUTPUT_PROFILES_TOTAL: 'output_profiles_total',
    OUTPUT_PROFILES_PAGE: 'output_profiles_page',
    OUTPUT_PROFILES_DEFAULTS: 'output_profiles_defaults',

    CAMPAIGNS: 'campaigns',
    CAMPAIGNS_TOTAL: 'campaigns_total',
    CAMPAIGNS_PAGE: 'campaigns_page',

    ADVERTISEMENTS: 'advertisements',
    ADVERTISEMENTS_TOTAL: 'advertisements_total',
    ADVERTISEMENTS_PAGE: 'advertisements_page',

    // Team domain
    TEAM_CURRENT: 'team',
    TEAM_SUBSCRIBED: 'subscribed',
    TEAM_MEMBERS: 'team_members',
    TEAM_MEMBERS_TOTAL: 'team_members_total',
    TEAM_MEMBERS_PAGE: 'team_members_page',

    // Team domain (super admin)
    TEAMS: 'teams',
    TEAMS_TOTAL: 'teams_total',
};
