<template>
    <v-dialog v-model="display" activator="#op-preview-dialog-btn" max-width="340">
        <v-card title="Add a preview">
            <template v-slot:text>
                <v-form @submit.prevent ref="addPreviewForm">
                    <v-select v-model="codec"
                              :items="allowedCodecs"
                              :rules="[$options.rules.oneOf(allowedCodecs)]"
                              required
                              label="Codec"
                              variant="outlined"
                              rounded="lg"/>

                    <v-text-field v-model="height"
                                  :rules="[$options.rules.gt(400)]"
                                  type="number"
                                  min="0"
                                  step="100"
                                  required
                                  label="Height"
                                  variant="outlined"
                                  rounded="lg"/>

                    <v-btn class="ml-auto"
                           type="submit"
                           text="Submit"
                           @click="submit"/>
                </v-form>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
import validationMixin from "../../Shared/Mixins/validationMixin.js";

const defaults = {
    codecs: ['H264'],
    codec: 'H264',
    height: 1080,
};

export default {
    mixins: [validationMixin],
    props: ['list'],
    data() {
        this.allowedCodecs = defaults.codecs;

        return {
            display: false,
            codec: defaults.codec,
            height: defaults.height,
        };
    },
    methods: {
        async submit() {
            if (!await this.validateForm(this.$refs.addPreviewForm)) {
                return;
            }

            const data = {
                codec: this.codec,
                height: this.height,
            };

            if (this.exists(data)) {
                // todo display error message
                return;
            }

            this.$emit('AddPreviewEvent', data);

            this.setDefaults();
        },
        setDefaults() {
            this.display = false;
            this.codec = defaults.codec;
            this.height = defaults.height;
        },
        exists(preview) {
            for (let p of this.list) {
                if (p.height === preview.height && p.codec === preview.codec) {
                    return true;
                }
            }
            return false
        }
    },
}
</script>
