<template>
    <div class="view-dcp">
        <v-row>
            <v-col cols="8">
                <generic-view-title v-if="attr.name" :title="'DCP: ' + attr.name">
                    <dcp-status-badge :dcp="attr"/>
                </generic-view-title>
            </v-col>

            <v-col cols="4">
                <v-sheet class="pa-6 pb-0 mb-6" rounded>
                    <panel-title icon="IconCarouselVertical" text="Previews"/>

                    <v-list v-if="attr.previews" density="compact" lines="two">
                        <v-list-item v-for="(p, i) in attr.previews"
                                     :key="p.id"
                                     class="pl-0 pr-0"
                                     :title="p.codec + ' (' + p.size + ')'"
                                     :subtitle="p.width + 'x' + p.height">

                            <template v-slot:prepend>
                                <v-btn :href="p.url"
                                       class="mr-3"
                                       target="_blank"
                                       icon="IconPlayerPlay"
                                       rounded="pill"
                                       variant="tonal"
                                       color="tertiary"/>
                            </template>

                            <!--<template v-slot:append>
                                <v-btn @click="(p) => (p)"
                                       icon="IconX"
                                       color="error" size="small" variant="tonal" rounded="sm" density="compact" />
                            </template>-->
                        </v-list-item>
                    </v-list>

                    <output-profile-preview-empty v-else msg="No preview videos yet"/>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import {VDateInput} from "vuetify/labs/VDateInput";
import Datepicker from "../../Shared/Components/Datepicker.vue";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import OutputProfilePreviewEmpty from "../../Plan/Components/OutputProfilePreviewEmpty.vue";

export default {
    mixins: [validationMixin, formHelperMixin],
    components: {
        OutputProfilePreviewEmpty,
        PanelTitle,
        DcpStatusBadge,
        Datepicker,
        GenericViewTitle,
        VDateInput,
    },
    data() {
        return {
            attr: {},
        }
    },
    created() {
        this.modelId = +this.$route.params.id;

        this.fetchModel(this.modelId);
    },
    methods: {
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('dcps/get', id)
                .then(attr => {
                    this.attr = this.displayFormat(
                        Object.assign(this.attr, { ...attr})
                    );
                });
        },
        /**
         * Format the raw model attributes for display.
         * @param {object} attr
         * @return {*}
         */
        displayFormat(attr) {
            return attr;
        },
    }
}
</script>
