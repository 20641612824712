<template>
    <div class="h-75 d-flex align-center justify-center text-center">
        <div>
            <v-icon icon="IconX" size="large" color="lighterror"/>
            <p>{{ msg }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        msg: {
            type: String,
            default: 'No preview definitions yet',
        }
    },
    data() {
        return {}
    },
}
</script>
