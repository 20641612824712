import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";

const prepareClient = axios.create({
    baseURL: '/api/v1/prepare/'
});

// Add the current team ID to all plan requests.
prepareClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

export default {
    /**
     * Fetch all resources.
     * @returns {Promise<array|null>}
     */
    getDcps(parameters) {
        let params = {
            page: parameters.page,
            per_page: parameters.perPage,
        }

        if (parameters.sortBy) {
            params.sort_by = parameters.sortBy;
            params.sort = parameters.sort;
        }

        return prepareClient.get('dcps', {params});
    },
    /**
     * Fetch a specific resource from the server.
     * @param {number} id
     * @returns {Promise<object|null>}
     */
    getDcp: id=> prepareClient.get(`dcps/${id}`),
    /**
     * Delete the resource.
     * @param {number} id
     * @returns {Promise<>}
     */
    deleteDcp: id => prepareClient.delete(`dcps/${id}`),
};
