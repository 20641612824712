import {useDate} from "vuetify";

export default {
    methods: {
        formatDate(dateStr, format = 'DD MMM YYYY HH:mm') {
            return useDate().formatByString(dateStr, format);
        },
        formatSeconds(seconds) {
            return new Date(seconds * 1000).toISOString().slice(11, 19);
        }
    }
}
