<template>
    <v-sheet class="pa-6 pb-0 mb-3 h-100" rounded>
        <h5 class="mb-3">
            <v-icon icon="IconCarouselVertical"/>
            Previews
            <v-btn id="op-preview-dialog-btn"
                   icon="IconPlus"
                   density="compact"
                   rounded="pill"
                   class="float-right"/>
        </h5>

        <output-profile-preview-dialog :list="previews" @add-preview-event="addPreview"/>

        <v-list v-if="previews.length" density="compact">
            <v-list-item v-for="(p, i) in previews" :key="p.id" class="pl-0 pr-0">
                <template v-slot:default>
                    <v-chip-group>
                        <v-chip :text="i"/>
                        <v-chip :text="'Codec: ' + p.codec" prepend-icon="IconLoader3" label />
                        <v-chip :text="p.height + 'px'" prepend-icon="IconRulerMeasure2" label />
                    </v-chip-group>
                </template>
                <template v-slot:append>
                    <v-btn @click="removePreview(p)"
                           icon="IconX"
                           color="error" size="small" variant="tonal" rounded="sm" density="compact" />
                </template>
            </v-list-item>
        </v-list>

        <output-profile-preview-empty v-else />
    </v-sheet>
</template>

<script>
import outputProfilePreviewDialog from "./OutputProfilePreviewDialog.vue";
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import OutputProfilePreviewEmpty from "./OutputProfilePreviewEmpty.vue";

export default {
    components: {
        OutputProfilePreviewEmpty,
        PanelTitle,
        outputProfilePreviewDialog
    },
    props: ['list'],
    data() {
        return {
            previews: this.list
        }
    },
    watch: {
        list(v) {
            this.previews = v;
        }
    },
    methods: {
        /**
         * Add a preview definition to the list.
         * @returns void
         */
        addPreview(preview) {
            this.previews.push(preview);

            this.$emit('change', this.previews);
        },
        /**
         * Remove a preview definition from the list.
         * @returns void
         */
        removePreview(preview) {
            this.previews = this.previews.filter(item => {
                return !(item.codec === preview.codec && item.height === preview.height);
            });

            this.$emit('change', this.previews);
        },
    },
}
</script>
