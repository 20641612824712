import Dcps from "./Views/Dcps.vue";
import Dcp from "./Views/Dcp.vue";

export default [
    /*  DCP routes
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    {
        path: '/dcps',
        component: Dcps,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/dcps/:id',
        component: Dcp,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
];
