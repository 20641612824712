import store from "../store.js";
import {createRouter, createWebHistory} from "vue-router";
import authRoutes from "../../Auth/authRoutes.js";
import teamRoutes from "../../Team/teamRoutes.js";
import dashboardRoutes from "../../Dashboard/dashboardRoutes.js";
import NotFound from "../Views/NotFound.vue";
import middlewarePipeline from "./Middleware/";
import planRoutes from "../../Plan/planRoutes.js";
import viewPreloader from "./viewPreloader.js";
import sharedRoutes from "../../Shared/sharedRoutes.js";
import Forbidden from "../Views/Forbidden.vue";
import adminRoutes from "../../Admin/adminRoutes.js";
import prepareRoutes from "../../Prepare/prepareRoutes.js";

/** Global app routes, the modules ones are imported from their respective folders. */
const appRoutes = [
    {
        path: '/',
        redirect: () => store.getters['auth/logged'] ? '/dashboard' : '/login',
    },
    {
        path: '/forbidden',
        name: 'notAuthorized',
        component: Forbidden
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: NotFound
    }
];

/** All Routes. */
const routes = [
    ...authRoutes,
    ...teamRoutes,
    ...dashboardRoutes,
    ...planRoutes,
    ...prepareRoutes,
    ...sharedRoutes,
    ...adminRoutes,
    ...appRoutes,
];

/** Vue router object. */
const router = createRouter({
    history: createWebHistory(),
    routes
});


/** Run the middleware defined for each route. See the route file in each domain. */
router.beforeResolve((to, from, next) => {
    const middleware = to.meta.middleware;

    // If there's middleware, only trigger the pipeline for path changes, not query parameters changes.
    // This avoids running the middleware on pagination inside views.
    if (!middleware || to.path === from.path) {
        next();
    } else {
        middlewarePipeline({to, from, next, store}, middleware);
    }
});

/** If a `preload` method is defined for the route's component, call it before resolving the component itself. */
// router.beforeResolve(viewPreloader(router, store));

export default router;
